<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>

  <v-alert v-else-if="!!errors" class="mt-4" color="error" text>
    <p class="font-weight-semibold mb-1">Messages</p>
    <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
      {{ error }}
    </p>
  </v-alert>

  <div v-else class="content-list">
    <div class="title-row">
      <h3>User Information</h3>
      <dialog-unjoin-user
        v-if="user && user.profile.account"
        :user="user"
        :organization="user.profile.account"
        :reload="reload"
      />
    </div>

    <v-row>
      <v-col cols="12" sm="6">
        <v-card v-if="user">
          <v-card-text>
            <div class="cont-info">
              <div class="text-info">
                <span class="text-bold">Name: {{ ' ' }}</span>
                <span>{{ user.profile.first_name }}</span>
              </div>
              <div class="text-info">
                <span class="text-bold">Last Name: {{ ' ' }}</span>
                <span>{{ user.profile.last_name }}</span>
              </div>
              <div class="text-info">
                <span class="text-bold">Email: {{ ' ' }}</span>
                <span>{{ user.email }}</span>
              </div>
              <div class="text-info">
                <span class="text-bold">Role: {{ ' ' }}</span>
                <span>{{ user_roles_string }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card>
          <v-card-text v-if="user && user.profile.account">
            <div class="cont-info">
              <div class="text-info">
                <span class="text-bold">Organization: {{ ' ' }}</span>
                <span>{{ user.profile.account ? user.profile.account.name : '' }}</span>
              </div>
              <div class="text-info">
                <span class="text-bold">Organization ID: {{ ' ' }}</span>
                <span>{{ user.profile.account ? user.profile.account.client_id : '' }}</span>
              </div>
              <div class="text-info">
                <span class="text-bold">Service: {{ ' ' }}</span>
                <span>{{ user.profile.account ? user.profile.account.service : '' }}</span>
              </div>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <div class="cont-info">
              <span class="text-bold">Organization</span>
              <span>No organization was found linked to this user.</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="row-content between">
      <h3>Select Organization</h3>
      <v-text-field
        label="Search"
        v-model="search.search"
        class="search"
        prepend-icon="mdi-magnify"
        clearable
        @click:clear="clearSearch"
        hide-details="auto"
      />
    </div>

    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="organizations"
          :loading="loadingOrganizations"
          sort-by="id"
          :sort-desc="sort_desc"
          :footer-props="{ 'items-per-page-options': items_per_page }"
          :options.sync="options"
          :server-items-length="total"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div>
              <div class="cont-actions">
                <dialog-select-organization :organization="item" :user="user" :roles="roles" :reload="reload" />
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'

// store
import store from '@/store'
//components
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import DialogSelectOrganization from './components/DialogSelectOrganization.vue'
import DialogUnjoinUser from './components/DialogUnjoinUser.vue'

export default {
  components: {
    ViewLoader,
    DialogSelectOrganization,
    DialogUnjoinUser,
  },
  setup() {
    const { router, route } = useRouter()

    //laoding
    const loading = ref(false)
    const loadingOrganizations = ref(false)

    //errors
    const errors = ref(null)

    const user = ref(null)
    const roles = ref(null)
    const organizations = ref([])

    const user_roles_string = ref('')

    //table
    const selected = ref(null)
    const total = ref(0)
    const headers = [
      { text: 'Client Id', value: 'client_id', sortable: true },
      { text: 'Organization', value: 'name', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: 200 },
    ]
    const items_per_page = ref([5, 10, 25, 50, 100])
    const sort_desc = ref(true)
    const options = ref({})
    const search = ref({
      search: '',
    })

    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const getRoles = async () => {
      loading.value = true
      await store
        .dispatch('role/getOrganizationRoles')
        .then(resp => {
          if (resp && resp.data) {
            roles.value = resp.data
          }
        })
        .finally(() => {
          loading.value = false
        })
    }
    const getUser = async data => {
      loading.value = true

      return await store
        .dispatch('user/getUser', route.value.params.user_id)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              user.value = resp.data.data

              if (user.value.roles) {
                user_roles_string.value = user.value.roles.map(x => x.name).join(', ')
              }
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }
    const getOrganizations = async data => {
      loadingOrganizations.value = true

      const params = new URLSearchParams({
        ...options.value,
        ...search.value,
      }).toString()

      return await store
        .dispatch('client/getOrganizations', params)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              organizations.value = resp.data.items
              total.value = resp.data.total
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loadingOrganizations.value = false
        })
    }
    const reload = data => {
      user.value = data

      if (user.value.roles) {
        user_roles_string.value = user.value.roles.map(x => x.name).join(', ')
      }
    }

    const clearSearch = () => {
      search.value.search = ''
    }

    onBeforeMount(() => {
      getRoles()
      getUser()
    })

    watch(
      options,
      () => {
        getOrganizations()
      },
      { deep: true },
    )

    watch(
      search,
      () => {
        getOrganizations()
      },
      { deep: true },
    )

    return {
      loading,
      loadingOrganizations,

      errors,

      user,
      roles,
      organizations,

      user_roles_string,

      total,
      headers,
      items_per_page,
      sort_desc,
      options,
      search,

      reload,
      clearSearch,
    }
  },
}
</script>

<style scoped>
.content-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cont-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}
.text-bold {
  font-weight: bold;
}
.text-info {
  font-size: 16px;
}
.row-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.row-content.between {
  justify-content: space-between;
}
.search {
  max-width: 500px;
}
.title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
