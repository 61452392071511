<template>
  <v-dialog v-model="dialog" persistent max-width="650">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" dense outlined>
        Select <v-icon> {{ icons.mdiAccountArrowLeftOutline }} </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5"> Change Organization </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <div class="content-text">
            <span>
              Are you sure to change the user's organization? once the changes have been applied the user will no longer
              be part of the previous organization and will be added to the organization of
              <span class="text-bold">{{ organization ? organization.name : '' }}</span>
            </span>
            <div>
              <v-select
                label="Select Role"
                v-model="role"
                :items="roles"
                item-text="name"
                item-value="name"
                outlined
                :rules="[validators.required]"
              />
            </div>
          </div>
        </v-form>

        <v-alert v-if="!!errors" class="mt-4" color="error" text>
          <p class="font-weight-semibold mb-1">Messages</p>
          <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
            {{ error }}
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false" :disabled="loading"> Close </v-btn>
        <v-btn color="green darken-1" outlined @click="submit">
          <span v-if="loading === false">Save</span>
          <span v-else>
            <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
            <span>Saving...</span>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import { mdiAccountArrowLeftOutline } from '@mdi/js'
// store
import store from '@/store'

export default {
  props: {
    user: Object,
    roles: Array,
    organization: Object,
    reload: Function,
  },
  setup(props) {
    const { user, roles, organization, reload } = props

    const loading = ref(false)
    const dialog = ref(false)
    const errors = ref(false)

    const role = ref(false)
    const form = ref(null)

    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const changeOrganization = async data => {
      loading.value = true

      return await store
        .dispatch('client/changeOrganization', data)
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              await setSnackbar({
                status: true,
                text: resp.data.message,
                color: 'primary',
              })

              errors.value = null
              dialog.value = false

              reload(resp.data.data)
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const submit = async () => {
      const check = form.value.validate()
      if (!check) {
        return
      }

      const data = {
        user_id: user.id,
        organization_id: organization.client_id,
        role: role.value,
      }

      await changeOrganization(data)
    }

    onBeforeMount(() => {
      if (user) {
        if (user.roles) {
          if (user.roles[0]) {
            role.value = user.roles[0].name
          }
        }
      }
    })

    return {
      loading,
      errors,
      dialog,

      form,

      role,

      submit,

      validators: {
        required,
      },

      icons: {
        mdiAccountArrowLeftOutline,
      },
    }
  },
}
</script>

<style scoped>
.content-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
