<template>
  <v-dialog v-model="dialog" persistent max-width="650">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="danger" v-bind="attrs" v-on="on" dense outlined>
        Unjoin <v-icon> {{ icons.mdiAccountRemoveOutline }} </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5"> Unjoin Organization </v-card-title>
      <v-card-text>
        <div class="content-text">
          <span>
            Are you sure to change the user's organization? once the changes have been applied the user will no longer
            be part of <span class="text-bold">{{ organization.name }}</span>
          </span>
        </div>

        <v-alert v-if="!!errors" class="mt-4" color="error" text>
          <p class="font-weight-semibold mb-1">Messages</p>
          <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
            {{ error }}
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="dialog = false" :disabled="loading"> Close </v-btn>
        <v-btn color="danger darken-1" outlined @click="submit">
          <span v-if="loading === false">Unjoin</span>
          <span v-else>
            <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
            <span>Saving...</span>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import { mdiAccountMultipleMinus, mdiAccountRemoveOutline } from '@mdi/js'
// store
import store from '@/store'

export default {
  props: {
    user: Object,
    organization: Object,
    reload: Function,
  },
  setup(props) {
    const { user, organization, reload } = props

    const loading = ref(false)
    const dialog = ref(false)
    const errors = ref(false)

    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const unjoin = async data => {
      loading.value = true

      return await store
        .dispatch('client/unjoinOrganization', data)
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              await setSnackbar({
                status: true,
                text: resp.data.message,
                color: 'primary',
              })

              errors.value = null
              dialog.value = false

              reload(resp.data.data)
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const submit = async () => {
      const data = {
        user_id: user.id,
      }

      unjoin(data)
    }

    return {
      loading,
      dialog,
      errors,

      submit,

      validators: {
        required,
      },

      icons: {
        mdiAccountMultipleMinus,
        mdiAccountRemoveOutline,
      },
    }
  },
}
</script>

<style scoped>
.content-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
